import React from "react"
import Layout from "../components/layout/layout"
import PageLayout from "../components/layout/page/page"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import LikeTo from "../components/layout/page/liketo"

const Services = () => (
  <Layout>
    <SEO title="Service" />
    <PageLayout name="Service" />
    <div className="container service">
      <div className="s-text text-center">
        We believe in simplicity. Increasing restaurant sales isn't accomplished
        by piecemealing services. Who orders a hamburger and receives the items
        unassembled do you? of course not! You get a perfectly cooked burger
        presented for your enjoyment. Unlike many digital agencies we provide
        just one service. Our solution is the only one restaurants need to
        ensure their long term success online. Our single solution encompasses
        everything you could need.
      </div>
      <div className="services-text ab-d">
        <h5>Your restaurant's website should get you more customers</h5>
        <p>
          The website of a restaurant is the first thing potential customers
          see. Seems obvious right but heres why that's worth stating. It can be
          the difference between a customer ordering from you or not, so it's
          important to have one that loads quickly and looks good. People focus
          on the good part ignoring the loads quickly part. A website with a
          loading time of two seconds will increase conversions by up to 80%.
          This is exactly why big business like Amazon, Walmart and Target all
          pay attention to website load times. They understand that if their
          respective sites take long loading, it's affecting their bottom line.
          This matters to restaurants because customers now order food from your
          site just like customers order products from Amazon or Target.
          <br />
          <br />
          There are countless ways to build a website. Many of those current
          solutions on the market do not put speed as a core focus. The result
          is a website that bleeds sales. Our focus for any client site is to
          convert what is already there into something much faster. Secondary
          goals are to improve layout and appearance. Long term success for
          getting more sales all starts with the right website. Let us help you
          convert your site into a money generating machine for your restaurant.
          Remember no matter how delicious your food might be, it won't get
          eaten if people have a bad impression of your restaurant using an old
          outdated, slow website.
        </p>
      </div>
      <div className="services-text ab-d">
        <h5>Provide a great ordering experience for customers</h5>
        <p>
          In today's economy, how customers order from a restaurant is a key
          part of the customer experience. It is important for any restaurant to
          have full control over their ordering process - not just because of
          its role in branding but also due to developing loyalty with
          customers. All major brands like Domino's control the ordering process
          because they are able to own their customer data. Restaurants who own
          their customer data like Domino's and Rubios Coastal Grill can
          mitigate marketing costs by owning their customer data. Regardless of
          how the economy performs they can comminicate with their customers at
          a faction of the cost. However, as more brands enter into this
          hospitality space, there will be increased competition on both fronts:
          capturing new customers while keeping current ones loyal. This means
          that restaurants must stay ahead through creativity to maintain an
          edge over other restaurants who may have different priorities or
          preferences when it comes to managing online orders.
          <br />
          <br />
          Our setup allow you to maintain 100% control of your customers, their
          data and the funds associated with the orders your site generates.
        </p>
      </div>
      <div className="services-text ab-d">
        <h5>
          Get your restaurant found in search, Maps and more on mobile or
          desktop
        </h5>
        <p>
          Google My Business (GMB) is a free service for businesses to manage
          their online local presence. GMB gives users the ability to review and
          rate your restaurant, post photos of your menu items, add events and
          announcements, or just upload a video about what makes you unique. GMB
          also offers rich analytics insights so you can understand how
          customers are finding you on Google search and map results. It
          provides zip code on where visitors are more densely located. It gives
          you the number of people that see your restaurant profile per month.
        </p>
        <StaticImage
          src="../images/services/services-google.png"
          alt="About"
          placeholder="blurred"
        />
        <p>
          Many restaurant owners ignore the importance of owning and managing
          this free service. Often times, another third party provides claim the
          listing to put a link to their website and ordering platform. This is
          simply claims your listing and then charges commissions for orders
          that come from your restaurant profile on GMB. it's important that if
          you don't have an account yet, take the time to create one today!
          <br />
          <br />
          We help with this process and help keep an eye on it for you. This
          listing along with our setup is responsible for $25,000 in orders a
          year... it's free traffic and commission free orders, for simply
          owning and controlling the listing.
        </p>
      </div>
      <div className="services-text ab-d">
        <h5>Direct mail is great to reach potential customers in your area</h5>
        <p>
          Nearly everything we do in our lives is done through a screen. We are
          constantly plugged into some type of device. While this may be
          convenient, it has also caused us to lose touch with one of the most
          important aspects of life: human interaction. This has never been
          truer after the COVID lockdowns. There is something about being
          face-to-face with another person that we cannot replicate digitally.
          This is why restaurants need hybrid marketing campaigns that merge
          both print and digital mediums.
          <br />
          <br />
          Some argue print is dead and digital is the way to go. The challenge
          with that school of thought is that not all customers are tech savvy.
          Meeting people where they are matters more so now than ever before.
          Reaching local customers online, through print and giving them options
          for orders matters a lot. This is exactly why we incorporate print
          media for every restaurant to ensure we reach customers across various
          generations and tech comfort levels... giving the range of customers
          ways to get in touch and come in for a memorable meal.
        </p>
      </div>
      <div className="services-text ab-d">
        <h5>Restaurants who send emails get more sales</h5>
        <p>
          Almost every business can benefit from using email marketing.
          Restaurants are no exception. In 2020, the restaurant industry lost a
          total of 240 Billion in sales. Every $1 spent on Email Marketing
          generally returns an average of $42. The majority of restaurants don't
          leverage email, this presents an opportunity for many. Recovering some
          of those sales is going to require a multi-pronged approach. Email can
          certainly be a component of that process. The key things needed for
          email are the right setup to make sure emails land in the inbox and
          the right content. The setup is something we do extremely well. The
          content is a collaborative process we work with clients to evolve.
          Emails allow restaurants to communicate with their customers on a more
          personal level, driving repeat sales and increasing customer loyalty.
          When used correctly, email can help restaurants stand out from the
          competition by providing valuable information about new dishes,
          events, and time-sensitive announcements. When we implement our system
          with clients, every order automatically get placed in a system
          allowing the restaurant to use that info for email marketing. This all
          comes with our setup and system at no additional cost or upsell. Allow
          us to help your restaurant use email to increase orders.
        </p>
      </div>
      <div className="services-text ab-d">
        <h5>Great Support Makes Everything Easier</h5>
        <p>
          We are here to support restaurant owners. We aren't simply service
          providers. We are also small business owners and know the struggles
          that come with the title. We are here to be an extensive resource for
          the majority of things technical. We pride ourselves on having some
          client relationships for over 18 years. We understand web technologies
          and everything surrounding them. We are here to help restaurant owners
          utilize web technologies to help them accomplish their goals.
          <br />
          <br />
          When we accept a new client, it's the beginning of a partnership
          without the paperwork or hassle that can come with such business
          arrangements. Let us help you improve your gross sales and make your
          journey to success just a little easier.
        </p>
      </div>
      <LikeTo
        header="Maximize your business potential with performant websites built with the latest, innovative technologies."
        url="/contact"
        urlName="Get Started"
      />
    </div>
  </Layout>
)

export default Services
